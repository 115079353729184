import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import {FormProvider} from "../../form/formContext";
import * as Yup from "yup";
import {Stack, Typography} from "@mui/material";
import {UsernamePasswordForm} from "./UsernamePasswordForm";
import {HelperLinks} from "./HelperLinks";
import queryString from "query-string";
import {SamlProvider} from "../samlContext";
import {GdprDialog} from "../../signup/steps/GdprDialog";
import {WithSamlIdp} from "../WithSamldp";
import {InitializeForm} from "./InitializeForm";
import {useLoginState, useLoginTranslation} from "../loginContext";
import {InfoMessage} from "../InfoMessage";

export const UsernamePasswordLogin = () => {
    const [lastLoginFailed, setLastLoginFailed] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const {
        responseType,
        state,
        redirectUri,
        clientId,
        nonce
    } = useLoginState()
    const t = useLoginTranslation()

    const samlLogin = identityProvider => {
        if(!loading) {
            setLoading(true)
        } else {
            return
        }

        const params = {
            identity_provider: identityProvider,
            response_type: responseType,
            state: state,
            redirect_uri: redirectUri
        };

        if(clientId) {
            params.client_id = clientId;
        }

        if(nonce) {
            params.nonce = nonce
        }

        window.location.href = `${window._env_.REACT_APP_APIGATEWAY}/auths/oauth2/external-idp?${queryString.stringify(params)}`;
    }

    const handleLogin = useCallback(formData => {
        const url = `${window._env_.REACT_APP_APIGATEWAY}/auths/oauth2/authorize`;
        const data = new URLSearchParams();
        data.append('username', formData.username.toLowerCase());
        data.append('password', formData.password);
        data.append('_csrf', uuidv4());
        data.append('state', state);
        data.append('response_type', responseType);
        data.append('redirect_uri', redirectUri);

        if(clientId) {
            data.append('client_id', clientId);
        }

        if(nonce) {
            data.append('nonce', nonce)
        }


        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            credentials: 'include',
            body: data
        }).then(response => {
            if(response.ok) {
                setLastLoginFailed(false);
                return response.text();
            } else if (response.status === 403) {
                // change password form...
                navigate(`/confirm/${formData.username}`);
            } else {
                throw Error('Login failed');
            }
        })
            .then(data => {
                if(data) {
                    if('/mfa' === data) {
                        navigate('/mfa')
                    } else {
                        window.location.href = data
                    }

                }
            })
            .catch(e => {
                console.log(e);
                setLoading(false);
                setLastLoginFailed(true)
            });
    }, [
        state,
        responseType,
        redirectUri,
        clientId
    ]);



    const onSubmit = formData => {
        if(!loading) {
            setLoading(true);
        } else {
            return
        }

        if(formData.samlDomain) {
            samlLogin(formData.samlDomain)
        } else {
            handleLogin(formData)
        }
    }

    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .email(t('validEmail', 'Må være en gyldig epostadresse'))
            .required(t('emailHelperText', 'Skriv inn e-posten din')),
        password: Yup.string()
            .required(t('passwordHelperText', 'Skriv inn passordet ditt'))
    })

    return (
        <FormProvider
            schema={validationSchema}
            onSubmit={onSubmit}
        >
            <InitializeForm />
            <SamlProvider>
                <GdprDialog />
                <WithSamlIdp>
                    <Typography variant='subtitle1' color='textPrimary'>
                        {t('loginWithUsernamePassword', 'Logg inn med brukernavn og passord')}
                    </Typography>

                        <Stack
                            alignItems={"stretch"}
                            sx={{
                                width: "100%",
                                mt: 2
                            }}
                        >
                            <UsernamePasswordForm
                                t={t}
                                disabled={loading}
                                lastLoginFailed={lastLoginFailed}
                                loading={loading}
                            />

                            <HelperLinks t={t} />
                        </Stack>
                    <InfoMessage />
                </WithSamlIdp>
            </SamlProvider>
        </FormProvider>
    )
}