import queryString from "query-string";
import {useLoginState} from "./loginContext";

export const useSamlLogin = () => {
    const {
        responseType,
        state,
        redirectUri,
        clientId,
        nonce
    } = useLoginState()

    return identityProvider => {
        const params = {
            identity_provider: identityProvider,
            response_type: responseType,
            state: state,
            redirect_uri: redirectUri
        };

        if(clientId) {
            params.client_id = clientId;
        }

        if(nonce) {
            params.nonce = nonce
        }

        window.location.href = `${window._env_.REACT_APP_APIGATEWAY}/auths/oauth2/external-idp?${queryString.stringify(params)}`;
    }
}