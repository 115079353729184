import React, {useReducer} from 'react'
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "../app/ProgressIndicator";
import {Stack} from "@mui/material";

export const SET_OAUTH_2_REQUEST_DATA = 'setOauth2RequestData'



const LoginTranslationContext = React.createContext(undefined)
const LoginStateContext = React.createContext(undefined)
const LoginDispatchContext = React.createContext(undefined)


const initialState = {
    responseType: 'code',
    clientId: null,
    redirectUri: "",
    state: null,
    nonce: null
}

const loginReducer = (state, action) => {
    console.debug({action})
    switch (action.type) {
        case SET_OAUTH_2_REQUEST_DATA:
            return {
                ...state,
                responseType: action.payload.responseType,
                clientId: action.payload.clientId,
                redirectUri: action.payload.redirectUri,
                state: action.payload.state,
                nonce: action.payload.nonce
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export const LoginProvider = ({children}) => {
    const {t, ready} = useTranslation(
        [
            'login.main',
            'login.newPassword',
            'login.resetPassword',
            'login.newUser',
            'login.passwordForm',
            'login.gdpr'
        ],
        {useSuspense: false}
    )
    const [state, dispatch] = useReducer(
        loginReducer,
        initialState,
        undefined
    )

    if(!ready) {
        return (
            <Stack
                alignItems={'center'}
                justifyContent={'center'}
            >
                <ProgressIndicator />
            </Stack>
        )
    }

    return (
        <LoginTranslationContext.Provider value={t}>
            <LoginStateContext.Provider value={state}>
                <LoginDispatchContext.Provider value={dispatch}>
                    {children}
                </LoginDispatchContext.Provider>
            </LoginStateContext.Provider>
        </LoginTranslationContext.Provider>
    )
}

export const useLoginTranslation = () => {
    const context = React.useContext(LoginTranslationContext)
    if (context === undefined) {
        throw new Error('useLoginTranslation must be used within a LoginProvider')
    } else {
        return context
    }
}

export const useLoginState = () => {
    const context = React.useContext(LoginStateContext)
    if (context === undefined) {
        throw new Error('useLoginState must be used within a LoginProvider')
    } else {
        return context
    }
}

export const useLoginDispatch = () => {
    const context = React.useContext(LoginDispatchContext)
    if (context === undefined) {
        throw new Error('useLoginDispatch must be used within a LoginProvider')
    } else {
        return context
    }
}